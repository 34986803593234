import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import "moment-timezone";
import { latestCryptoNewsByViews } from "../../api/ApiUtils";

function News(props) {
  const [cryptoNews, setCryptoNews] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [recordsCount, setRecordsCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [Pages, setPages] = useState(0);

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await latestCryptoNewsByViews(pageNo, limit);
      // console.log(response.data.data.news_feeds.news_feeds);
      setCryptoNews(response.data.data.news_feeds.news_feeds);
      setRecordsCount(response.data.data.news_feeds.total_news_feeds);
      setPages(response.data.data.news_feeds.pages);
    }
    fetchMyAPI();
  }, []);

  return (
    <div className="mt-5 p-2" style={{ border: "2px" }}>
      <Card
        style={{ width: "100%", borderRadius: "0px" }}
        className="mb-3 justify-content-center"
      >
        <h2>Popular - Trending</h2>
        {cryptoNews?.length ? (
          cryptoNews.map((newsItem, i) => {
            return (
              <div className="row" key={i}>
                <div className="col">
                  <a href={`/newsitem/${newsItem.url_slug}`}>
                    <br />
                    {newsItem.title}
                  </a>
                  <br />
                  {newsItem.views_count} views | Posted on{" "}
                  {newsItem.createdAt.split("T")[0].replace("-", "/")}
                </div>
              </div>
            );
          })
        ) : (
          <Spinner animation="grow" variant="primary" />
        )}
      </Card>
    </div>
  );
}

export default News;
