import Container from 'react-bootstrap/Container'

import Header from '../components/header'
import Footer from '../components/footer'
// import Sidebar from "../components/Sidebar"
import HorizontalBanner from "../components/adsBanners/horizontal"
import PostLink from "../components/postLink/index.js"



function App() {
  return (
    <div className="App">
      <Header />

       <Container className="main-body">
         <h1 className='d-none'>Post Link</h1>
          <HorizontalBanner />
          <PostLink />
        
       </Container>
        
     <Footer/>
    </div>
  );
}

export default App;



