import {
  CRYPTOCOMPARE_API_BASE_URL,
  NOMICS_API_BASE_URL,
  NOMICS_ACCESS_KEY,
  API_BASE_URL,
  accesstoken,
  ACCESS_KEY,
} from "./BaseUrls";

import axios from "axios";
// const axios = require('axios');

export const http = axios.create({
  baseURL: CRYPTOCOMPARE_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: localStorage.getItem("accesstoken"),
  },
});

const request = (options) => {
  var headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    // 'Access-Control-Allow-Headers': 'Content-Type',
    // 'Access-Control-Allow-Headers': 'true',
    // 'Access-Control-Allow-Headers': 'GET,HEAD,OPTIONS,POST,PUT',
    // 'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers ',
    // 'Access-Control-Allow-Headers': '*'
  };

  // console.log(localStorage.getItem(accesstoken));

  if (localStorage.getItem(accesstoken)) {
    // console.log(localStorage.getItem(accesstoken));
    // axios.defaults.headers.common["Authorization"] = accesstoken;
    headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);
  // console.log(options.headers);
  // var postmethod = options.method;
  if (options.method === "POST") {
    return axios
      .post(options.url, options.body, { headers: headers })
      .then(function (response) {
        //  console.log(response);
        return response;
      })
      .catch(function (err) {
        console.log(err);
        if (err.request && err.request.status === 403) {
          let appState = {
            isLoggedIn: false,
            user: {},
          };
          localStorage.removeItem("accesstoken");
          localStorage.removeItem("copid");
          localStorage["appState"] = JSON.stringify(appState);
          //   this.props.history.push('/login');
          window.location.reload();
        }
        // console.log(err.message)
        // console.log(err.request)
        const error = {
          error: err,
        };

        return error;
      });
  }

  if (options.method === "GET") {
    return axios({
      method: "get",
      url: `${options.url}access_key=${ACCESS_KEY}`,
      data: options.body,
      headers: headers,
    });
  }
  if (options.method === "NOMICS_GET") {
    return axios({
      method: "get",
      url: `${options.url}key=${NOMICS_ACCESS_KEY}`,
      data: options.body,
      // headers: headers
    });
  }

  if (options.method === "CUSTOM_GET") {
    return axios({
      method: "get",
      url: `${options.url}`,
      data: options.body,
      headers: headers,
    });
  }
};

//User Login API
export function userLogin(data) {
  //  console.log(loginrequest);
  return request({
    url: CRYPTOCOMPARE_API_BASE_URL + "/user/login",
    method: "POST",
    body: data,
  });
}

//User register API
export function userRegister(data) {
  return request({
    url: CRYPTOCOMPARE_API_BASE_URL + "/user/register",
    method: "POST",
    body: data,
  });
}

//return all the spenditure items from the api
export function topListByMarketCap(pageNo, limit) {
  return request({
    url:
      CRYPTOCOMPARE_API_BASE_URL +
      "/top/mktcapfull?tsym=USD&page=" +
      pageNo +
      "&limit=" +
      limit +
      "&",
    method: "GET",
    body: null,
  });
}

//return all the latest crypto news
export function latestCryptoNews(pageNo, limit) {
  return request({
    url:
      API_BASE_URL + "/api/v1/news_feeds?pageNo=" + pageNo + "&limit=" + limit,
    method: "CUSTOM_GET",
    body: null,
  });
}
export function latestCryptoNewsByViews(pageNo, limit, views) {
  return request({
    url:
      API_BASE_URL +
      "/api/v1/news_feeds?pageNo=" +
      pageNo +
      "&limit=" +
      limit +
      "&orderBy=views",
    method: "CUSTOM_GET",
    body: null,
  });
}

//submit a news item to the database
export function postNewsitem(data) {
  return request({
    url: API_BASE_URL + "/api/v1/news_feeds",
    method: "POST",
    body: data,
  });
}

//return single news item by urlslug
export function fetchSingleNewsbySlug(url_sug) {
  return request({
    url: API_BASE_URL + "/api/v1/news_feeds/" + url_sug,
    method: "CUSTOM_GET",
    body: null,
  });
}

//return all the the exchanges general info
export function exchangesInfos(pageNo, limit) {
  return request({
    url: CRYPTOCOMPARE_API_BASE_URL + "/exchanges/general?tsym=USD&",
    method: "GET",
    body: null,
  });
}

//return a single crypto assset from Nomics
export function fetchSingleCryptoAssets(symbol, attributes) {
  return request({
    url:
      NOMICS_API_BASE_URL +
      `/currencies?ids=${symbol}&attributes=${attributes}&`,
    method: "NOMICS_GET",
    body: null,
  });
}
export function fetchSingleCryptoAssetsV2(symbol, attributes) {
  return request({
    url: API_BASE_URL + "/api/v1/coins/" + symbol,
    method: "CUSTOM_GET",
    body: null,
  });
}

export function fetchSingleCryptoAssetsLocal(symbol) {
  return request({
    url: API_BASE_URL + "/api/v1/coins/local/" + symbol,
    method: "CUSTOM_GET",
    body: null,
  });
}

//submit learn registration form
export function submitLearnRegistrationForm(data) {
  return request({
    url: API_BASE_URL + "/api/v1/learn",
    method: "POST",
    body: data,
  });
}

//search function for the project
export function SearchEndpoint(model, term, limit, pageNo) {
  return request({
    url:
      API_BASE_URL +
      `/api/v1/search?model=${model}&term=${term}&limit=${limit}&pageNo=${pageNo}`,
    method: "GET",
    body: null,
  });
}

//submit a glossary to the database
export function postGlossary(data) {
  return request({
    url: API_BASE_URL + "/api/v1/glossary",
    method: "POST",
    body: data,
  });
}

//return all the latest glossary items
export function latestGlossary(pageNo, limit) {
  return request({
    url: API_BASE_URL + "/api/v1/glossary?pageNo=" + pageNo + "&limit=" + limit,
    method: "CUSTOM_GET",
    body: null,
  });
}

//return single glossary item by urlslug
export function fetchSingleGlossarybySlug(url_sug) {
  return request({
    url: API_BASE_URL + "/api/v1/glossary/" + url_sug,
    method: "CUSTOM_GET",
    body: null,
  });
}
