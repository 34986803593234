import Container from "react-bootstrap/Container";

import Header from "../components/header";
import Footer from "../components/footer";
// import Sidebar from "../components/Sidebar"
import HorizontalBanner from "../components/adsBanners/horizontal";
import NewsComponent from "../components/news";

function App() {
  return (
    <div className="App">
      <Header />

      <Container className="main-body">
        <h1 className="d-none">News</h1>
        <HorizontalBanner />
        <NewsComponent />
      </Container>

      <Footer />
    </div>
  );
}

export default App;
