import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(4),
  },
  input: {
    width: '60%',
  },
}));

function App() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            My Brand Name
          </Typography>
        </Toolbar>
      </AppBar>
      <form className={classes.form} noValidate autoComplete="off">
        <TextField
          className={classes.input}
          label="Search"
          variant="outlined"
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
        />
        <Button variant="contained" color="primary">
          Submit
        </Button>
      </form>
    </div>
  );
}

export default App;