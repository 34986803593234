import React from 'react';

function createMarkup() {
    return {
        __html: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2092375407734215"
        crossorigin="anonymous"></script>
   <!-- Coinjupiter header -->
   <ins class="adsbygoogle"
        style="display:block"
        data-ad-client="ca-pub-2092375407734215"
        data-ad-slot="9651707935"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
   <script>
        (adsbygoogle = window.adsbygoogle || []).push({});
   </script>
   `
};
  }

function HorizontalBanner(props) {
    return (
    
        <div dangerouslySetInnerHTML={createMarkup()} />
    );
}


export default HorizontalBanner