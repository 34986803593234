const GLOBAL_STRINGS = {

    
    COMPANY_NAME: () => {
        return "CoinJupiter";
    },

    COMPANY_DESCRIPTION: () => {
        return `CoinJupiter will bring you up to date crypto prices and news`;
    },

    COMPANY_EMAIL: () => {
        return `CoinJupiter@gmail.com`;
    },

    COMPANY_PHONE_NUMBER: () => {
        return `+254799667788`;
    },

    //function to update the coporate user
    COMPANY_ADDRESS: () => {
        return `44 Kimathi street, Nairobi, Kenya`;
    },
    SOCIAL_NETWORK_CONNECT_PHRASE: () => {
        return `Socials:`
    }

 }

 module.exports = GLOBAL_STRINGS

