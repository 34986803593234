import React from 'react';
import {Nav, Navbar, Container} from 'react-bootstrap'
import GLOBAL_STRINGS from '../assets/strings';
import Logo from '../assets/img/logo.jpg'
import HorizontalBanner from "../components/adsBanners/horizontal"

function Header(props) {
  return (
<Container>
<div class="jumbotron">
<HorizontalBanner/>
</div>
        <Navbar collapseOnSelect fixed='top' expand='md' bg='dark' variant='dark'>
        <Navbar.Brand href="/home" className="d-none d-md-block pl-2">
        <img
        alt=""
        src={Logo}
        width="30"
        height="30"
        className="d-inline-block align-top pl-2"
      />{' '}
          {GLOBAL_STRINGS.COMPANY_NAME()}
          </Navbar.Brand>
          <Container className="content-right">
            <Navbar.Toggle aria-controls='responsive-navbar-nav' />
            <Navbar.Collapse id='responsive-navbar-nav'>
              <Nav>
                  <Nav.Link href="/home">Home</Nav.Link>
                  <Nav.Link href="/exchanges">Exchanges</Nav.Link>
                  <Nav.Link href="/news">News</Nav.Link>
                  <Nav.Link href="/glossary">Glossary</Nav.Link>
                  <Nav.Link href="https://sentiment.coinjupiter.com">Crypto Sentiments</Nav.Link>
                  <Nav.Link href="https://blog.coinjupiter.com">Blog</Nav.Link>
                  <Nav.Link href="/post-link">Post Link</Nav.Link>
                  {/* <Nav.Link href="/search">Search</Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        </Container>
    );
  }


export default Header