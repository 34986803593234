import React from "react";
import Banner from "../../../assets/img/odin.jpg";

function createMarkup() {
  return {
    __html: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2092375407734215"
        crossorigin="anonymous"></script>
   <!-- coinjupiter sidebar -->
   <ins class="adsbygoogle"
        style="display:block"
        data-ad-client="ca-pub-2092375407734215"
        data-ad-slot="7189690960"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
   <script>
        (adsbygoogle = window.adsbygoogle || []).push({});
   </script>

   <!--<a href="https://www.anrdoezrs.net/click-100744903-15161330" target="_top">
<img src="https://www.awltovhc.com/image-100744903-15161330" width="300" height="250" alt="" border="0"/></a> -->

<a href="https://runa.odinprotocol.io/validators" target="_top">
<img src=${Banner} width="300" height="300" alt="" border="0"/></a>`,
  };
}

function SquareBanner(props) {
  return <div dangerouslySetInnerHTML={createMarkup()} />;
}

export default SquareBanner;
