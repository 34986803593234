import Container from 'react-bootstrap/Container'


import Auth from '../components/pages/auth'


function App() {
  return (
    <div className="App">
      <Auth/>
    </div>
  );
}

export default App;



