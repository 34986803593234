import React from 'react';
// import {Nav, Navbar, Container} from 'react-bootstrap'
// import GLOBAL_STRINGS from '../assets/strings';
// import Logo from '../assets/img/logo.jpg'
import SquareBanner from "../adsBanners/square"
import GlossaryComponent from '../glossary/sidebarGlossary'
import NewsSidebar from './newsSidebar'
import Verticalbanner from "../adsBanners/vertical"

function Sidebar(props) {
  return (
    <div>
      <SquareBanner/>
      <NewsSidebar/>
      <SquareBanner/>
      <GlossaryComponent/>
      <Verticalbanner/>
      </div>
    );
  }


export default Sidebar