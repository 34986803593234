// export const API_BASE_URL = "http://localhost:3500";
export const API_BASE_URL = "https://dev.elidayjuma.com/chisendi";

export const accesstoken = "accesstoken";
export const CRYPTOCOMPARE_API_BASE_URL =
  "https://min-api.cryptocompare.com/data";
export const NOMICS_API_BASE_URL = "https://api.nomics.com/v1";
export const ACCESS_KEY =
  "d81425deeefaf7bcf52d5cca4d906948b383dbaa83f643b2e28c998307d606e8";
export const NOMICS_ACCESS_KEY = "7a65da81f2a879851ed00d73af5eee2143470f5d";

// https://chisendi-api.herokuapp.com/user/login
