import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form} from 'react-bootstrap';
import 'moment-timezone';
import { postNewsitem } from '../../api/ApiUtils';

import ScrollButton from '../htmlElements/scrollButton';
import Sidebar from '../Sidebar';


function News(props) {
  const [newsRoomName, setNewsRoomName] = useState();
  const [newsRoomLogoUrl, setNewsRoomLogoUrl] = useState();
  const [newsLink, setNewsLink] = useState();
  const [newsTitle, setNewsTitle] = useState();
  const [newsSnippet, setNewsSnippet] = useState();
  const [newsTag, setNewsTag] = useState();

  

  // useEffect(() => {
  //   async function fetchMyAPI() {
     
  //     console.log("response.data.data.news_feeds.news_feeds");
      
  //   }
  //   fetchMyAPI()
  // }, [])
const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {};
    data.news_room_name = newsRoomName;
    data.title = newsTitle;
    data.body = newsSnippet;
    data.tag = newsTag;
    data.news_room_logo_url = newsRoomLogoUrl;
    data.news_item_url = newsLink;

    console.log("submmiting page", data)
    const response = await postNewsitem(data)
    console.log(response.data);
   
  }
  const handleNewsRoomNameChange = (event) => setNewsRoomName(event.target.value);
  const handleNewRoomLogoURLChange = (event) => setNewsRoomLogoUrl(event.target.value);
  const handleNewsLinkeChange = (event) => setNewsLink(event.target.value);
  const handleNewsTitleChange = (event) => setNewsTitle(event.target.value);
  const handleNewsSnippetChange = (event) => setNewsSnippet(event.target.value);
  const handleTagselectedChange = (event) => setNewsTag(event.target.value);

  return (
    <div className="mt-5">
        
      <div className = "row">
        <div className="col-12 col-lg-8" style={{background: "cream" }}>
        {/* TODO: Place your form here!! */}
        <Form className="mb-5" onSubmit={handleSubmit}>
          <Row>
            <h2>Share your news link with millions other cryptoNews readers.</h2>
          </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridFname">
            <Form.Label>News room Name</Form.Label>
            <Form.Control 
              type="text" 
              name="newsRoomName"
              onChange={handleNewsRoomNameChange}
              placeholder="News room name e.g CNBC" />
          </Form.Group>
      
          <Form.Group as={Col} controlId="formGridLname">
            <Form.Label>New room logo URL</Form.Label>
            <Form.Control 
              type="text"
              name="newsRoomLogoUrl" 
              onChange={handleNewRoomLogoURLChange}
              placeholder="News room logo URL" />
          </Form.Group>
        </Row>
      
        <Form.Group as={Col} controlId="floatingTextarea">
            <Form.Label>News Link</Form.Label>
            <Form.Control 
              type="text"
              name="newsLink"
              onChange={handleNewsLinkeChange}
              placeholder="Link to the news item" />
          </Form.Group>

          <Form.Group as={Col} controlId="floatingTextarea">
            <Form.Label>News Title</Form.Label>
            <Form.Control 
              type="text"
              name="newsTitle"
              onChange={handleNewsTitleChange}
              placeholder="Title to the news item" />
          </Form.Group>
      
        <Form.Group className="mb-3" controlId="formGridNote">
          <Form.Label>News Snippet</Form.Label>
          <Form.Control 
            as="textarea"
            rows={5}
            name="newsSnippet" 
            onChange={handleNewsSnippetChange}
            placeholder="Type a short snippet for the news." />
        </Form.Group>

      
          <Form.Group as={Col} className="mb-3" controlId="formGridState">
            <Form.Label>Select appropriate tags</Form.Label>
            <select class="form-select" name="tagSelected" onChange={handleTagselectedChange} aria-label="Default select example">
              <option selected value="BlockChain">BlockChain</option>
              <option value="Cryptocurrency">Cryptocurrency</option>
              <option value="Bitcoin">Bitcoin</option>
              <option value="Shiba">Shiba</option>
              <option value="Ethereum">ETH</option>

            </select>
          </Form.Group>  
      
        {/* <Form.Group className="mb-3" id="formGridCheckbox">
          <Form.Check 
            type="checkbox" 
            name="consent"
            onChange={(e) => this.handleInputChange(e)}
            label="I don't mind receiving newsletters" />
        </Form.Group> */}
      
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
        </div>
        <div className="col-sm-block col-lg-4" style={{background: "white" }}>
          <Sidebar/>
        </div>

      </div>
    
      <ScrollButton />
    </div>

  );
}


export default News