import React from 'react';


function createMarkup() {
    return {
        __html: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2092375407734215"
        crossorigin="anonymous"></script>
   <!-- Coinjupiter vertical side -->
   <ins class="adsbygoogle"
        style="display:block"
        data-ad-client="ca-pub-2092375407734215"
        data-ad-slot="5435957299"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
   <script>
        (adsbygoogle = window.adsbygoogle || []).push({});
   </script>`
};
  }

function VerticalBanner(props) {
    return (

        <div dangerouslySetInnerHTML={createMarkup()} />
    );
}


export default VerticalBanner