import Container from 'react-bootstrap/Container'

import Header from '../header'
import Footer from '../footer'


function App() {
  return (
    <div className="App">
      <Header />

       <Container className="pt-5">
          Login data here
       </Container>
        
     <Footer />
    </div>
  );
}

export default App;



